const scrollOff = function(e) {
  e.preventDefault();
}; // イベントキャンセルメソッド

const naviSpan = document.getElementsByClassName("spNaviButton"); // SPナビ用ボタンSPANタグ取得
const navUL = document.getElementsByClassName("navWrap"); // ナビゲーションラッパー取得
const mainWrap = document.getElementById("mainWrap"); // fix要にメインラッパー取得

let scrollY = 0.0; // fixed用位置記憶変数
let fixedFlag = false; // メニューfixフラグ

// SPナビ用ボタンをタップされたらイベント開始
// (ナビゲーション開閉＆スクロール位置記憶固定)
naviSpan[0].addEventListener("touchend", () => {
  // fixフラグを確認
  if (!fixedFlag) {
    // OFFであれば現在スクロールを取得
    scrollY = window.pageYOffset;
    // fixフラグを真に
    fixedFlag = true;
  } else {
    // ONであればfixフラグを偽に
    fixedFlag = false;
  }
  // SPボタンに指定クラスがあるか確認
  if (naviSpan[0].classList.contains("activeNavigation")) {
    // あればメニューモードなのでそれぞれのクラスを除外後、スクロールリスナーを外す
    mainWrap.classList.remove("fixedMainWrap");
    naviSpan[0].classList.remove("activeNavigation");
    navUL[0].classList.remove("openNavi");
  } else {
    // なければメニューモードに移行処理、スクロールリスナーを付ける
    mainWrap.classList.add("fixedMainWrap");
    naviSpan[0].classList.add("activeNavigation");
    navUL[0].classList.add("openNavi");
  }
  // fixを付与・解除した後の処理
  if (fixedFlag) {
    // ONであればmainWrapのスタイルにTOPを現在スクロール値を与える
    mainWrap.style.top = -scrollY + "px";
    document.documentElement.style.scrollBehavior = "auto";
  } else {
    // OFFであれば
    window.scrollTo(0, scrollY);
    document.documentElement.style.scrollBehavior = "";
  }
});

// サブメニュー開閉処理
// document.getElementById("nav1").addEventListener("touchstart",  (event) => { openSubList(event,"nav1"); }, { passive: false });
// document.getElementById("nav2").addEventListener("touchstart",  (event) => { openSubList(event,"nav2"); }, { passive: false });
// document.getElementById("nav3").addEventListener("touchstart",  (event) => { openSubList(event,"nav3"); }, { passive: false });
// document.getElementById("nav4").addEventListener("touchstart",  (event) => { openSubList(event,"nav4"); }, { passive: false });

// サブメニュー開閉処理
// function openSubList(e,id) {
//   // デフォルトイベント停止
//   e.preventDefault();
//   // 押されたIDの情報を取得
//   let nlist_id = document.getElementById(id);
//   // 押されたidタグにmoreListクラスが含まれていれば消す
//   if (nlist_id.classList.contains('moreList')) {
//     nlist_id.classList.remove('moreList');
//   }
//   // 無ければ押されたものを開いてそれ以外は閉じる
//   else {
//     // ID分回す(増えたら追加)
//     ["nav1","nav2","nav3","nav4"].map( (NAVID) => {
//       if(NAVID === id) {
//         // 該当IDだけ開く
//         document.getElementById(NAVID).classList.add('moreList');
//       }
//       else {
//         // 押されたIDと該当しないものが開いていれば閉じる
//         document.getElementById(NAVID).classList.remove('moreList');
//       }
//     });

//   }
// }
