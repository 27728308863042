// news block

var webAll = {
  distance: "0%",
  opacity: 0,
  delay: 300,
  easing: "ease-in-out",
  duration: 1000
};

var pic1 = {
  distance: "10%",
  opacity: 0,
  delay: 300,
  easing: "ease-in-out",
  duration: 1200
};

var pic2 = {
  distance: "10%",
  opacity: 0,
  delay: 400,
  easing: "ease-in-out",
  duration: 1400
};
var pic3 = {
  distance: "10%",
  opacity: 0,
  delay: 500,
  easing: "ease-in-out",
  duration: 1600
};

ScrollReveal().reveal(".sov01", webAll);
ScrollReveal().reveal(".sov02", pic1);
ScrollReveal().reveal(".sov03", pic2);
ScrollReveal().reveal(".sov04", pic3);
